:local {
  .page {
    position: relative;
    width: 342px; // 8,56cm bei 40ppcm
    height: 214px; // 5.398cm bei 40ppcm
    background-color: white;
    page-break-after: always;
    font-family: 'Berlin Type Office', sans-serif;
    font-size: 15.4px;
  }
}
